<script>
import { default as SchoolAddValidation } from "../../validations/SchoolAddValidation";
import Service from "@/service/index.js";
const choose = "Choose...";
let g_course, g_vue;
export default {
  name: "SchoolAdd",
  props: {
    isOpen: { default: true, type: Boolean },
    schoolList: Array
  },
  data() {
    return {
      school: {
        institution: "",
        address1: "",
        address2: "",
        country: choose,
        state: choose,
        city: "",
        postal: "",
        //course: ""
      },
      countryList: [],
      stateList: []
    };
  },
  watch: {
   
  },
  mounted() {
    // console.log("SchoolAdd opened");
    this.getCountrtyList();
    let userSchool = JSON.parse(localStorage.getItem("userSchool"));

    if (userSchool) {
      if (
        !this.schoolList.find(school => school.id == userSchool.institute_id)
      ) {
        // userSchool exists in localStorage but not yet added to Parent schoolList
        // which means, somehow page reloaded but didn't destroy local storage.
        localStorage.removeItem("userSchool");
        localStorage.removeItem("userProgram");
        return;
      }

      this.school = userSchool;

      console.log("this.school:", this.school);
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.school[name];
      return $dirty ? !$error : null;
    },
    invalidityFor(category,validation){
      const { $dirty } = this.$v.school[category];
      const _validation = this.$v.school[category][validation];
      return $dirty && !_validation;
    },
    getCountrtyList: async function() {
      // let countryList = await this.$http.get("/countries");
      // if (countryList && countryList.data && countryList.data.data) {
      //   this.countryList = countryList.data.data;
      // }
      new Service(this).getCountrtyList(
        /* success Callback */
        data => {
          this.countryList = data;
          if (localStorage.getItem("userSchool")) {
            new Service(this).getStateList(
              this.school.country,
              /* success Callback */
              data => {
                this.stateList = data;
              }
            );
          }
        }
      );
    },
    getState: async function() {
      this.stateList = [];
      this.$v.school.state.$model = choose;
      if (this.$v.school.country.chooseOption) {
        new Service(this).getStateList(
          this.school.country,
          /* success Callback */
          data => {
            this.stateList = data;
          }
        );
      }
    },
    popupClose: function() {
      this.$emit("closePopup");
    },
    saveSchool: async function() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.school.institute_id = -1;
      this.school.course_id = -1;
      localStorage.setItem("userSchool", JSON.stringify(this.school));
      this.$emit("addedDeatils", this.school);

    }
  },
  validations: SchoolAddValidation
};
</script>
<template>
  <b-container class="popup add-school-popup">
    <!-- :style="'display: ' + (isOpen ? 'block;' : 'none;')" -->
    <!-- {{isOpen}} -->
    <section class="section-b-gap">
      <!-- <a href="javascript:void(0)" class="close" @click="popupClose()">
            <i class="material-icons">close</i>
            </a>
            <h3 class="text-center">Add your school.</h3> -->
      <h3 class="text-center">If your school was not listed..</h3>
      <p class="text-center">
        When adding a new educational institution, please fill all information accurately, as this information will also be reviewed for approval.
      </p>
    </section>
    <b-form @submit.prevent="saveSchool">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label=" Educational Institution Name">
            <b-form-input
              type="text"
              placeholder
              v-model="$v.school.institution.$model"
              :state="validateState('institution')
              "
            />
            <b-form-invalid-feedback
              v-if="invalidityFor('institution','required')
              "
              >This field is required</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Address 1">
            <b-form-input
              type="text"
              placeholder
              v-model="$v.school.address1.$model"
              :state="validateState('address1')"
            />
            <b-form-invalid-feedback
              v-if="invalidityFor('address1','required')
              
              "
              >This field is required</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Address 2">
            <b-form-input
              type="text"
              placeholder
              v-model="$v.school.address2.$model"
            />
            <!-- 
                     <b-form-invalid-feedback
                       v-if="$v.school.address2.$dirty && !$v.school.address2.required"
                       >This field is required
                     </b-form-invalid-feedback>
                     -->
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Country">
            <b-form-select
              v-model="$v.school.country.$model"
              :state="validateState('country')
              "
              v-on:change="getState()"
            >
              <!-- This slot appears above the options from 'options' prop -->
              <template v-slot:first>
                <b-form-select-option value="Choose..." disabled
                  >-- Please select an option --</b-form-select-option
                >
              </template>
              <b-form-select-option
                :value="country.id"
                v-for="(country, index) in countryList"
                :key="index"
                >{{ country.name }}</b-form-select-option
              >
            </b-form-select>
            <b-form-invalid-feedback
              v-if="invalidityFor('country','chooseOption')"
              >Choose a country.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="State">
            <b-form-select
              v-model="$v.school.state.$model"
              :state="validateState('state')
              
              "
            >
              <!-- This slot appears above the options from 'options' prop -->
              <template v-slot:first>
                <b-form-select-option value="Choose..." disabled
                  >-- Please select an option --</b-form-select-option
                >
              </template>
              <b-form-select-option
                :value="state.id"
                v-for="(state, index) in stateList"
                :key="index"
                >{{ state.name }}</b-form-select-option
              >
            </b-form-select>
            <b-form-invalid-feedback
              v-if="invalidityFor('state','chooseOption')"
              >Choose a state.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="City">
            <b-form-input
              type="text"
              placeholder
              v-model="$v.school.city.$model"
              :state="validateState('city')"
            />
            <b-form-invalid-feedback
              v-if="invalidityFor('city','required')"
              >This field is required</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              v-else-if="invalidityFor('city','alpha')"
              >This field requires only alphabet characters
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Postal/Zip Code">
            <b-form-input
              type="text"
              placeholder
              v-model="$v.school.postal.$model"
              :state="validateState('postal')
              "
            />
            <b-form-invalid-feedback
              v-if="invalidityFor('postal','required')"
              >This field is required</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <!-- <b-form-group label="Course/ Major">
            <b-form-input
              type="text"
              placeholder
              v-model="$v.school.course.$model"
              :state="validateState('course')
              "
            />
            <b-form-invalid-feedback
              v-if="invalidityFor('course','required')"
              >This field is required</b-form-invalid-feedback
            >
          </b-form-group> -->
        </b-col>
        <b-col class="text-right">
          <b-button type="button" variant="default" @click="popupClose()"
            >Cancel</b-button
          >
          <b-button type="button" variant="primary" @click="saveSchool"
            >Save</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>
