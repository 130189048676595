<script>
import toggleMenu from "@/helper/toggleMenu";
export default {
  methods: {
    toggleMenu: toggleMenu,
    navigateTo(path) {
      this.$router.push({ path: path }).catch(err => {
        this.$router.go();
      });
    }
  },
  data() {
    return {
      menuList: ["login", "register", "about",]
    };
  }
};
</script>
<template>
  <header>
    <b-navbar toggleable="lg" type="light" variant="light" class="header-menu">
      <b-navbar-brand to="/">
        <img class='logo' src="../assets/images/logo.png" alt="CAST Soft Logo"
      /></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item
            v-for="(item, index) of menuList"
            :to="'/' + item"
            :key="index">
              {{ item }}
          </b-nav-item>
        </b-navbar-nav>

        <!-- <b-navbar-nav class="ml-auto">
          <b-nav-item to="/registration">Sign Up</b-nav-item>
        </b-navbar-nav>-->
      </b-collapse>
    </b-navbar>
  </header>
</template>
