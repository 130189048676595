import Vue from 'vue'
import { Fragment } from 'vue-fragment'
import DefaultLayout from './layouts/DefaultLayout.vue'
import AccountFunctionalityLayout from './layouts/AccountFunctionalityLayout'
// import Header from './sections/Header.vue'
// import Footer from './sections/Footer.vue'

import SchoolAdd from './components/common/SchoolAdd'
import ProgramAdd from './components/common/ProgramAdd'

Vue.component('v-fragment', Fragment)
// Defining Layouts
Vue.component('default-layout', DefaultLayout)
Vue.component('accountFunctionality-layout', AccountFunctionalityLayout)
// Defining Page Sections
// Vue.component('mm-header', Header)
// Vue.component('mm-footer', Footer)

Vue.component('mm-school-add', SchoolAdd)
Vue.component('mm-program-add', ProgramAdd)
