import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home";
import Login from "../views/Login";
import { default as guard } from "@/guard/index";
// import Registration from "../views/registration/Registration";
// import StudentRegistration from "../views/registration/StudentRegistration";
// import PageNotFound from "../views/PageNotFound";
// import LostPassword from "../views/resetPassword/LostPassword";
// import ResetPassword from "../views/resetPassword/ResetPassword";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  // {
  //   path: "/",
  //   name: "home",
  //   meta: { layout: "default" },
  //   component: Home
  // },
  {
    path: "/test-notification",
    name: "test-notification",
    component: () =>
      import(
        /* webpackChunkName: "TestNotification" */ "../components/TestNotification"
      )
  },
  {
    path: "/login",
    name: "login",
    meta: { notLoggedIn: true },
    component: Login
    // () => import(/* webpackChunkName: "Login" */ "../views/Login")
  },
  {
    path: "/registration",
    name: "registration",
    meta: { layout: "default", notLoggedIn: true },
    component: () =>
      import(
        /* webpackChunkName: "Registration" */ "../views/registration/Registration"
      )
  },
  {
    path: "/signup",
    alias: '/register',
    redirect: "/registration"
  },
  {
    path: "/registration/student/create",
    name: "new-registration",
    meta: { layout: "default", notLoggedIn: true },
    component: () =>
      import(
        /* webpackChunkName: "StudentRegistration" */ "../views/registration/StudentRegistration"
      )
  },
  {
    path: "/registration/teacher/create",
    name: "teacher-registration",
    meta: { layout: "default", notLoggedIn: true },
    component: () =>
      import(
        /* webpackChunkName: "TeacherRegistration" */ "../views/registration/TeacherRegistration"
      )
  },
  {
    path: "/registration/other/create",
    name: "other-registration",
    meta: { layout: "default", notLoggedIn: true },
    component: () =>
      import(
        /* webpackChunkName: "OtherRegistration" */ "../views/registration/OtherRegistration"
      )
  },
  {
    path: "/registration/success",
    name: "registration-success",
    meta: { layout: "default", notLoggedIn: true },
    component: () =>
      import(
        /* webpackChunkName: "registration-success" */ "../views/registration/success"
      )
  },
  {
    path: "/lost-password",
    name: "lost-password",
    meta: { layout: "default", notLoggedIn: true },
    component: () =>
      import(
        /* webpackChunkName: "LostPassword" */ "../views/resetPassword/LostPassword"
      )
  },
  {
    path: "/reset-password/:id/:timestamp/:time_duration",
    name: "reset-password",
    meta: { layout: "default", notLoggedIn: true },
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ "../views/resetPassword/ResetPassword"
      )
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { layout: "accountFunctionality", requiresAuth: true  }, // requiresAuth needs to be true, for testing 'false'
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "../views/accountFunctionality/Dashboard"
      )
  },
  {
    path: "/update",
    redirect:"/student-update"
  },
  {
    path: "/profile",
    redirect:"/student-profile"
  },
  {
    path: "/student-update",
    alias: '/student-profile',
    name: "student-update",
    meta: {
      layout: "accountFunctionality",
      requiresAuth: true,
      isUpdate: true
    }, // requiresAuth needs to be true, for testing 'false'
    component: () =>
      import(
        /* webpackChunkName: "StudentUpdate" */ "../views/accountFunctionality/StudentUpdate"
      )
  },
  {
    path: "/teacher-update",
    alias: '/teacher-profile',
    name: "teacher-update",
    meta: {
      layout: "accountFunctionality",
      requiresAuth: true,
      isUpdate: true
    }, // requiresAuth needs to be true, for testing 'false'
    component: () =>
      import(
        /* webpackChunkName: "TeacherUpdate" */ "../views/accountFunctionality/TeacherUpdate"
      )
  },
  {
    path: "/other-update",
    alias: '/other-profile',
    name: "other-update",
    meta: {
      layout: "accountFunctionality",
      requiresAuth: true,
      isUpdate: true
    }, // requiresAuth needs to be true, for testing 'false'
    component: () =>
      import(
        /* webpackChunkName: "OtherUpdate" */
        "../views/accountFunctionality/OtherUpdate"
      )
  },
  {
    path: "/update-password",
    name: "UpdatePassword",
    meta: { layout: "accountFunctionality", requiresAuth: true }, // requiresAuth needs to be true, for testing 'false'
    component: () =>
      import(
        /* webpackChunkName: "UpdatePassword" */
        "../views/accountFunctionality/UpdatePassword.vue"
      )
  },
  {
    path: "/training",
    name: "Training",
    meta: { layout: "accountFunctionality", requiresAuth: true, requiredActive:true }, // requiresAuth needs to be true, for testing 'false'
    component: () =>
      import(
        /* webpackChunkName: "training" */
        "../views/accountFunctionality/Training"
      )
  },
  {
    path: "/download",
    name: "Download",
    meta: { layout: "accountFunctionality", requiresAuth: true, requiredActive:true },
    component: () => import("../views/accountFunctionality/Download")
  },
  {
    path: "/about",
    name: "About",
    meta: { layout: "default" }, // requiresAuth needs to be true, for testing 'false'
    component: () =>
      import(
        /* webpackChunkName: "about" */
        "../views/About.vue"
      )
  },

  {
    path: "*",
    name: "Page Not Found",
    meta: { layout: "default" },
    component: () =>
      import(/* webpackChunkName: "PageNotFound" */ "../views/PageNotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  //base: process.env.BASE_URL,
  routes
});

// Guard
router.beforeEach(guard);

export default router;
