<script>
import { FadeTransition } from "vue2-transitions";
import DefaultHeader from "../sections/DefaultHeader";
import DefaultFooter from "../sections/DefaultFooter";
export default {
  name: "DefaultLayout",
  components: {
    FadeTransition,
    DefaultHeader,
    DefaultFooter
  }
};
</script>

<template>
  <v-fragment>
    <DefaultHeader />
    <fade-transition :duration="200" mode="out-in">
      <main class="main-container">
        <slot />
      </main>
    </fade-transition>
    <DefaultFooter />
  </v-fragment>
</template>
