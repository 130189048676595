<template>
  <div class="main-body-section">
    <div class="login-wraper paddingb300">
      <div class="sign-up-form-container">
        
      </div>
    </div>
  </div>
</template>

<script>

import http from '../http'
export default {
  name: 'home',
  mounted () {
    http.get('/countries')
    // this.getData();
    // console.log('@@env',process.env.VUE_APP_ROOT_API)
  },
  methods: {
    getData: function () {
      const test = this.$http.get('/countries')
    }
  }
}
</script>
