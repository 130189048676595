export default {
  baseURL: `https://edu-api.cast-soft.com/api/`,// `http://admin.cast.local/api`,//`https://edu-admin.not4prod.com/api/`, 
  settings: {url: "settings", verb: 'get'},
  login: {url:"login", verb: 'post'},
  sign_up: {url:"sign-up", verb: 'post'},
  add_institute: {url:"add-institute", verb: 'post'},
  add_program: {url:"add-program", verb: 'post'},
  update_program:{url:"update-program",verb:'post'},
  email_exist: {url:'email-exist', verb:'post'},
  countries:{url:'countries', verb:'get'},
  states: {url:'states', verb:'get'},
  products:{url:'products', verb:'get'},
  save_details: {url:'save-details' , verb:'post'},
  get_details: {url:'my-details' , verb:'post'},
  get_training: {url:'get-training' , verb:'get'},
  get_download: {url:'get-download' , verb:'get'},
  change_password: {url:'change-password' , verb:'post'},
  getImage : {url:'images', verb:'get'},

  institutes: "institutes",
  programs: {url:"programs", verb: 'get'},
  proof_types: "proof-types",
  forgot_password_link: "forgot-password-link",
  reset_password: "reset-password"
};
