import Vue from "vue";
import App from "./App.vue";
import Vuelidate from "vuelidate";
import Notifications from "vue-notification";
import VueLazyLoad from 'vue-lazyload';
import { BootstrapVue, IconsPlugin, BSpinner } from 'bootstrap-vue';
import VueSimpleAlert from "vue-simple-alert";


import "./registerServiceWorker";
import router from "./router";
import http from "./http";
import "./globalComponent";
import i18n from './i18n'
import VueAuthHref from 'vue-auth-href'

import('./assets/css/style.css')

// Doc: https://vuejsexamples.com/simple-alert-for-vue-js/
Vue.use(VueSimpleAlert);
Vue.use(Notifications);
Vue.prototype.$http = http;

Vue.use(Vuelidate);
Vue.use(VueLazyLoad);

Vue.use(VueAuthHref)


// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.component('b-spinner', BSpinner)

Vue.config.productionTip = false;

Vue.mixin({
  data: function() {
    return {
      g_menu:null
    }
  }
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
