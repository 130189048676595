import Service from "@/service/index.js";
export default async function(to, from, next) {
  let user;

  user = JSON.parse(localStorage.getItem("userdata"));
  if (user == null) {
    try {
      await new Service().getDetails(
        { id: user.id, user_type: user.user_type },
        data => {
          //console.log("Set userdata @beforeEach route ");
          user = data;
          localStorage.setItem("userdata", JSON.stringify(data));
        }, /* failCallback */ undefined,
        /* errorCallback */
        ()=>{
          console.log('error callback');
          // this.$notify({
          //   title: "Sorry!!",
          //   text: "Session expired. Please login again.",
          //   type: "warn"
          // });
          // alert("Session expired. Please login again.");
          // localStorage.removeItem("userdata");
          // localStorage.removeItem("token");
          user=null;
        }
      )
    } catch (e) {
      user = null;
    }
  }
  
  // changing layout
  if (user != null) {
    to.meta.layout = "accountFunctionality";
  } else {
    to.meta.layout = "default";
  }

  // console.log('My user:',user);
  // console.log('To:',to);
  // guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // for Account functionality
    if (user == null) {
      
      next({
        path: "/login"
      });
    } else if (to.meta.requiredActive!==undefined && to.matched.some(record => record.meta.requiredActive)) {
      if (user.status!='DA' ) {
        next({
          path: "/dashboard"
        });
      } else {
        next();
      }
    }
    else if (to.meta.isUpdate) {
      let pathOfUpdate={S:'/student-update',T:'/teacher-update',O:'/other-update'};
      if(to.path.includes('update') && to.path!=pathOfUpdate[user.user_type].replace("profile","update")){
        next({
          path: pathOfUpdate[user.user_type].replace("profile","update")
        });
      }
      else if(to.path.includes('profile') && to.path!=pathOfUpdate[user.user_type].replace("update","profile")){
        next({
          path: pathOfUpdate[user.user_type].replace("update","profile")
        });
      }
      else{
        next();
      }
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.notLoggedIn)) {
    // for Login, lost-password, reset-password
    if (user != null) {
      next({
        path: "/dashboard"
      });
    } else {
      next();
    }
  } else {
    next();
  }
}
