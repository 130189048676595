<template>
  <div id="app">
    <notifications />
      <component :is="layout">
        <router-view/>
      </component>
  </div>
</template>

<script>
// require('@/assets/css/bootstrap.css')
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
require('@/assets/css/style.css')
require('@/assets/css/responsive.css')
const default_layout = 'default'

export default {
  name: 'app',
  components: {
  },
  computed: {
    layout () {
      let _layout;
      // if (localStorage.getItem("userdata") != null) {
      //   _layout='accountFunctionality-layout';
      // }
      // else{
      //   _layout=(this.$route.meta.layout || default_layout) + '-layout';
      // }
      _layout=(this.$route.meta.layout || default_layout) + '-layout';
      return _layout;
    }
  },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  }
}
</script>

<style lang="scss">
// @import './assets/css/style.css';
// @import '@/assets/css/responsive.css';

.date_picker{
    display: block !important;
    width: 100% !important;
}
</style>
<style src="vue-step-indicator/dist/vue-step-indicator.css"></style>
