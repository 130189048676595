import { required, numeric } from "vuelidate/lib/validators";

export default {
  program: {
    institution: {  }, //required
    program: { required },
    faculty: { required },
    program_length: { required, numeric }
  }
};
