<script>
import { required } from "vuelidate/lib/validators";
import togglePassword from "@/helper/togglePassword";
import Service from "@/service/index.js";

// import http from "../http";
export default {
  name: "Login",
  validations: {
    email: { required },
    password: { required }
  },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  mounted() {
    if (localStorage.userdata != undefined) {
      this.$notify({
        type: "warn",
        title: this.$t("login.dialog.session_expired_title"),
        text: this.$t("login.dialog.session_expired_body")
      });
      localStorage.removeItem("userdata");
      localStorage.removeItem("token");
    }
  },
  methods: {
    togglePassword: togglePassword,
    validateState(name) {
      return this.$v[name].$dirty
        ? !this.$v[name].$invalid
          ? null
          : false
        : null;
    },
    login() {
      // stop here if form is invalid
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.loading = true
      this.$refs.loginButton.disabled = true;
      new Service(this).login(
        { email: this.email.toLowerCase(), password: this.password },
        /* successful callback */
        data => {
          localStorage.setItem("userdata", JSON.stringify(data.user));
          localStorage.setItem("token", data.access_token);
          this.loading = false
          this.$router.push({ path: "/dashboard" });
        },
        /* failCallback */ 
        (res)=>{
          this.$refs.loginButton.disabled = false;
          this.loading = false
        },
        /* errorCallback */
        err => {
          this.$refs.loginButton.disabled = false;
          this.loading = false
        }
      );
    }
  }
};
</script>
<template>
   <b-container class="page login">
      <div class="content-wrap narrow">
         <b-form @submit.prevent="login">
            <h1 class="text-center">{{ $t("login.title") }}</h1>

            <b-form-group :label="$t('forms.email')">
               <b-form-input
                  type="text"
                  placeholder
                  v-model="$v.email.$model"
                  :state="validateState('email')"
                  />
               <b-form-invalid-feedback
                  >{{ $t("forms.err.required") }}</b-form-invalid-feedback
                  >
            </b-form-group>
            <b-form-group :label="$t('forms.password')">
               <b-input-group>
                  <b-form-input
                     type="password"
                     placeholder
                     v-model="$v.password.$model"
                     :state="validateState('password')"
                     />
                  <b-input-group-append>
                     <b-button
                        type="button"
                        class="view-password-btn"
                        variant="outline-primary"
                        @click="togglePassword($event.target)"
                        >
                        <i class="material-icons" style="display:none">visibility</i>
                        <i class="material-icons" style="display:inline-block">visibility_off</i>
                     </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback
                     >{{ $t("forms.err.required") }}</b-form-invalid-feedback
                     >
               </b-input-group>
            </b-form-group>
            <b-form-group class="text-center">
              <b-button variant="primary" type="submit" ref="loginButton" block>
                <b-spinner v-if="loading" small label="Spinning"></b-spinner>
                {{ $t("forms.submit") }}
              </b-button>
            </b-form-group>
            <p class="text-center">
               <router-link to="/registration">{{ $t("login.create_account") }}</router-link>
            </p>
            <p class="mb-0 text-center">
               <router-link to="/lost-password">{{ $t("login.forgot_password") }}</router-link>
            </p>
         </b-form>
      </div>
   </b-container>
</template>
