<script>
import { default as ProgramAddValidation } from "../../validations/ProgramAddValidation";
import Service from "@/service/index.js";

export default {
  name: "ProgramAdd",
  props: {
    isOpen: { default: true, type: Boolean },
    programList: Array,
    institutionName: String,
  },
  data() {
    return {
      program: {
        institution: "",
        program: "",
        faculty: "",
        program_length: ""
      },
      shift_left4number: false
    };
  },
  mounted() {
    console.log("ProgramAdd opened", this.institutionName);
    this.program.institution = this.institutionName;
    let userSchool = JSON.parse(localStorage.getItem("userSchool"));

    let userProgram = JSON.parse(localStorage.getItem("userProgram"));

  },
  watch: {
 
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.program[name];
      return $dirty ? !$error : null;
    },
    invalidityFor(category,validation){
      const { $dirty } = this.$v.program[category];
      const _validation = this.$v.program[category][validation];
      return $dirty && !_validation;
    },
    mouseleave4number($e) {
      if ($e.target != document.activeElement) {
        this.shift_left4number = false;
      }
    },
    popupClose: function() {
      this.$emit("closePopup");
    },
    saveProgram() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.program.institute_id = -1;
      this.program.course_id = -1;
      localStorage.setItem("userProgram", JSON.stringify(this.program));
      this.$emit("addedDeatils", this.program);
      
    }
  },
  validations: ProgramAddValidation
};
</script>
<template>
  <b-container class="popup add-program-popup">
    <!-- :style="'display: ' + (isOpen ? 'block;' : 'none;')" -->
    <section class="section-b-gap">
      <!-- <a href="javascript:void(0)" class="close" @click="popupClose()">
            <i class="material-icons">close</i>
            </a>
            <h3 class="text-center">Add your Program.</h3> -->
      <h3 class="text-center">If your Program was not listed..</h3>
      <p class="text-center">
        Please provide details about the program / course you are taking.
      </p>
    </section>
    <b-form @submit.prevent="saveProgram">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Educational Institution Name">
            <b-form-input
              type="text"
              placeholder
              readonly
              v-model="$v.program.institution.$model"
              :state="validateState('institution')
              "
            />
            <b-form-invalid-feedback
              v-if="invalidityFor('institution','required')
              "
              >This field is required</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Program Name">
            <b-form-input
              type="text"
              placeholder
              v-model="$v.program.program.$model"
              :state="validateState('program')
              "
            />
            <b-form-invalid-feedback
              v-if="invalidityFor('program','required')"
              >This field is required</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Program Faculty">
            <b-form-input
              placeholder
              v-model="$v.program.faculty.$model"
              :state="validateState('faculty')
                
              "
            />
            <b-form-invalid-feedback
              v-if="invalidityFor('faculty','required')"
              >This field is required</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Program Length (in Year)">
            <b-form-input
              type="number"
              min="0"
              placeholder
              
              v-model="$v.program.program_length.$model"
              :state="validateState('program_length')
              "
            />
            <b-form-invalid-feedback
              v-if="invalidityFor('program_length','required')
              "
              >This field is required</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              v-else-if="invalidityFor('program_length','numeric')
              "
              >This field requires a positive numeric
              value</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col class="text-right">
          <b-button type="button" variant="default" @click="popupClose()"
            >Cancel</b-button
          >
          <b-button type="button" variant="primary" @click="saveProgram"
            >Save</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>
<style scoped>
.shift_left {
  /* right: 27px; */ /*for button */
  background-position: right 33px top 50%; /* for validation icon */
}
</style>
