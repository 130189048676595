// import axios from "axios";

import ApiUrls from "./ApiUrls";
import http from "@/http.js";
import sha256 from 'crypto-js/sha256';

export default class Service {
  vue;
  constructor(vue) {
    this.vue = vue;
  }
  async getSettings( successCallback, failCallback, errorCallback){
    await http
      .request({
        method: ApiUrls.settings.verb,
        url:
          ApiUrls.settings.url
      })
      .then(response => {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          successCallback(response.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          failCallback(response.data);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
        if (errorCallback !== undefined) {
          errorCallback(error);
        }
      });
  }
  async getAllTraining( successCallback, failCallback, errorCallback){
    await http
      .request({
        method: ApiUrls.get_training.verb,
        url:
          ApiUrls.get_training.url,
        headers: {
          Authorization: `Bearer ${localStorage.token}`
        }
      })
      .then(response => {
        // console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          successCallback(response.data.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          failCallback(response.data);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
        if (errorCallback !== undefined) {
          errorCallback(error);
        }
      });
  }
  async getAllDownload( successCallback, failCallback, errorCallback){
    await http
      .request({
        method: ApiUrls.get_download.verb,
        url:
          ApiUrls.get_download.url,
        headers: {
          Authorization: `Bearer ${localStorage.token}`
        }
      })
      .then(response => {
        // console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          successCallback(response.data.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          failCallback(response.data);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
        if (errorCallback !== undefined) {
          errorCallback(error);
        }
      });
  }
  login(formdata, successCallback, failCallback, errorCallback) {
    http
      .request({
        method: ApiUrls.login.verb,
        url: ApiUrls.login.url,
        data: formdata
      })
      .then(response => {
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          successCallback(response.data);
          return;
        }
        this.defaultFailCallback(response);
        if(failCallback!==undefined){
          failCallback(response);
        }
        
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
        if (errorCallback !== undefined) {
          errorCallback(error);
        }
      });
  }
  async saveDetails(item, successCallback, failCallback) {
    try{
      const form_data = new FormData();
    
    // for (let [key, value] of Object.entries(item)) {
    //   form_data.append(key, value);
    // }

    // for (var key in item) {
    //   console.log('=>',key, item[key])
    //   form_data.append(key, item[key]);
    // }

    // Object.keys(item).forEach(key => form_data.append(key, item[key]));

  //  console.log("@saveDetails, formdata: ", form_data, item);
    await http
      .request({
        method: ApiUrls.save_details.verb,
        url: ApiUrls.save_details.url,
        headers: {
          Authorization: `Bearer ${localStorage.token}`
        },
        data: item
      })
      .then(response => {
        // console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          successCallback(response.data.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          failCallback(response.data);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
      });
    }catch(e){
      console.log('Error:', e)
    }
    
  }
  async getDetails(formdata, successCallback, failCallback, errorCallback) {
    await http
      .request({
        method: ApiUrls.get_details.verb,
        url:ApiUrls.get_details.url,
        data:formdata,
        headers: {
          Authorization: `Bearer ${localStorage.token}`
        }
      })
      .then(response => {
        // console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          successCallback(response.data.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          failCallback(response.data);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
        if (errorCallback !== undefined) {
          errorCallback(error);
        }
      });
  }
  getStateList(country_id, successCallback, failCallback) {
    http
      .request({
        method: ApiUrls.states.verb,
        url: ApiUrls.states.url,
        params:{country_id}
      })
      .then(response => {
        // console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          successCallback(response.data.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          failCallback(response.data);
        }
      })
      .catch(function(error) {
   
        console.warn("Backend Isssue:-", error);
      });
  }
  getCountrtyList(successCallback, failCallback) {
    http
      .request({
        method: ApiUrls.countries.verb,
        url: ApiUrls.countries.url
      })
      .then(response => {
        // console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          successCallback(response.data.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          failCallback(response.data);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
      });
  }
  async emailExist(email, successCallback, failCallback) {
    // let formdata = {
    //     institute_id: institute_id
    // };
    let hash = sha256(email+email).toString();
    await http
      
      .request({
        method: ApiUrls.email_exist.verb,
        url: ApiUrls.email_exist.url ,
        data: {email, hash}
      })
      .then(response => {
        // console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          successCallback(response.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          failCallback(response.data);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
      });
  }
  dateInFormat(date) {
    if (date) {
      let _selectedDateObject = date; // this.student.student_info.graduation_date
      let _month = _selectedDateObject.getMonth() + 1;
      _month = _month > 9 ? _month : "0" + _month;
      let _date = _selectedDateObject.getDate();
      _date = _date > 9 ? _date : "0" + _date;
      return _selectedDateObject.getFullYear() + "-" + _month + "-" + _date;
    }
    return date;
  }
  updateProgram(formdata, successCallback, failCallback) {
    http
      .request({
        method: ApiUrls.update_program.verb,
        url: ApiUrls.update_program.url,
        data: formdata
      })
      .then(response => {
     //   console.log("Update Program:", response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          this.vue.$notify({
            title: "Success!",
            text: "Program Saved Successfully",
            type: "success"
          });
          // alert("Correct Username & Password!");
          successCallback(response.data.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          this.failCallback(response);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
      });
  }
  signUp(student, successCallback, failCallback) {
    var fd = new FormData();
    fd.append("user_type", "S");
    fd.append("email", student.personal_info.email);
    fd.append("password", student.personal_info.password);
    fd.append("first_name", student.personal_info.firstname);
    fd.append("last_name", student.personal_info.lastname);
    fd.append("country_id", student.personal_info.country);
    fd.append("state_id", student.personal_info.state);
    fd.append("city", student.personal_info.city);
    fd.append("zipcode", student.personal_info.postal);
    fd.append("institute_id", student.student_info.institution);
    fd.append("program_id", student.student_info.course);
    fd.append("student_id", student.student_info.student_id);
    fd.append(
      "graduation_date",
      this.dateInFormat(student.student_info.graduation_date)
    );
    fd.append("currently_in_year", student.student_info.current_year);
    fd.append("proof_2", student.proof_enrolement.proof2.file);
    fd.append("proof_1", student.proof_enrolement.proof1.file);
    fd.append("proof_1_type_id", student.proof_enrolement.proof1.proof_type);
    fd.append("proof_2_type_id", student.proof_enrolement.proof2.proof_type);
    fd.append("product_id", student.proof_enrolement.product);
    if (student.proof_enrolement.comment) {
      fd.append("comments", student.proof_enrolement.comment);
    }

    http
      .request({
        method: ApiUrls.sign_up.verb,
        url: ApiUrls.sign_up.url,
        data: fd,
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(response => {
      //  console.log("Signup Response:", response);
        if (
          response.data &&
          response.data.status &&
          response.data.status == "success"
        ) {
          this.vue.$notify({
            title: "Success!",
            text: "Successfully registered.",
            type: "success "
          });

          successCallback(response.data.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          this.failCallback(response);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
      });
  }
  signUpTeacher(teacher, successCallback, failCallback) {
    var fd = new FormData();
    fd.append("user_type", "T");
    fd.append("email", teacher.teacher_requirement.email);
    fd.append("password", teacher.teacher_requirement.password);
    fd.append("first_name", teacher.teacher_requirement.firstname);
    fd.append("last_name", teacher.teacher_requirement.lastname);
    fd.append("country_id", teacher.teacher_requirement.country);
    fd.append("state_id", teacher.teacher_requirement.state);
    fd.append("city", teacher.teacher_requirement.city);
    fd.append("zipcode", teacher.teacher_requirement.postal);

    fd.append("institute_id", teacher.about_program.institution);
    fd.append("program_id", teacher.about_program.course);
    fd.append("job_title", teacher.about_program.job_title);
    fd.append("class_size", teacher.about_program.class_size);
    fd.append("proof_2", teacher.about_program.proof2.file);
    fd.append("proof_1", teacher.about_program.proof1.file);
    fd.append("proof_1_type_id", teacher.about_program.proof1.proof_type);
    fd.append("proof_2_type_id", teacher.about_program.proof2.proof_type);
    fd.append("product_id", teacher.about_program.product);

    http
      .request({
        method: ApiUrls.sign_up.verb,
        url: ApiUrls.sign_up.url,
        data: fd,
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(response => {
      //  console.log("Signup Response:", response);
        if (
          response.data &&
          response.data.status &&
          response.data.status == "success"
        ) {
          this.vue.$notify({
            title: "Success!",
            text: "Successfully registered.",
            type: "success "
          });

          successCallback(response.data.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          this.failCallback(response);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
      });
  }
  signUpOther(other, successCallback, failCallback) {
    var fd = new FormData();
    fd.append("user_type", "O");
    fd.append("email", other.email);
    fd.append("password", other.password);
    fd.append("first_name", other.firstname);
    fd.append("last_name", other.lastname);
    fd.append("country_id", other.country);
    fd.append("state_id", other.state);
    fd.append("city", other.city);
    fd.append("zipcode", other.postal);
    fd.append("not_qualify_reason", other.not_qualify_reason);
    fd.append("hear_about_WYSIWYG", other.hear_about);
    fd.append("product_id", other.product);

    http
      .request({
        method: ApiUrls.sign_up.verb,
        url: ApiUrls.sign_up.url,
        data: fd,
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(response => {
      //  console.log("Signup Response:", response);
        if (
          response.data &&
          response.data.status &&
          response.data.status == "success"
        ) {
          this.vue.$notify({
            title: "Success!",
            text: "Successfully registered.",
            type: "success "
          });

          successCallback(response.data.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          this.failCallback(response);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
      });
  }
  async addProgram(program, successCallback, failCallback) {
    let formdata = {
      institute_id: program.institute_id,
      program_name: program.program,
      program_faculty: program.faculty,
      program_length: program.program_length
    };
    if (program.institution) formdata.institute_name = program.institution;

    await http
      .request({
        method: ApiUrls.add_program.verb,
        url: ApiUrls.add_program.url,
        data: formdata
      })
      .then(response => {
      //  console.log("addProgram Response:", response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          this.vue.$notify({
            title: "Success!",
            text: "Program Saved Successfully",
            type: "success"
          });
          // alert("Correct Username & Password!");
          // console.log("False", response.data.status);
          successCallback(response.data.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          this.failCallback(response);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
      });
  }
  getPrograms(params, successCallback, failCallback) {
    // structure of params is {institute_id is number , user_id is number}
    http
      .request({
        method: ApiUrls.programs.verb,
        url: ApiUrls.programs.url,
        params: params,
      })
      .then(response => {
        // console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          successCallback(response.data.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          failCallback(response);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
      });
  }
  async addInstitute(school, successCallback, failCallback) {
    let formdata = {
      institution_name: school.institution,
      address_1: school.address1,
      country_id: school.country,
      state_id: school.state,
      city: school.city,
      zipcode: school.postal,
      course_name: school.course
    };
    if (school.address2) {
      formdata.address_2 = school.address2;
    }
    await http
      .request({
        method: ApiUrls.add_institute.verb,
        url: ApiUrls.add_institute.url,
        data: formdata
      })
      .then(response => {
        // console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          this.vue.$notify({
            title: "Success!",
            text: "Institute Saved Successfully",
            type: "success"
          });
          // alert("Correct Username & Password!");
          // console.log("False", response.data.status);
          successCallback(response.data.data);
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          this.failCallback(response);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
      });
  }
  async updatePassword(items, successCallback, failCallback) {
    try{
      await http
        .request({
          method: ApiUrls.change_password.verb,
          url: ApiUrls.change_password.url,
          headers: {
            Authorization: `Bearer ${localStorage.token}`
          },
          data: items
        })
        .then(response => {
          // console.log(response);
          if (
            response.data &&
            response.data.status &&
            response.data.status === "success"
          ) {
            successCallback(response.data.data);
            return;
          }else {
            this.defaultFailCallback(response);
            if (failCallback !== undefined) { failCallback(response.data);}
          }
        })
        .catch(function(error) {
          console.warn("Backend Isssue:-", error);
        });
    }catch(e){
      console.log('Error:', e)
    }
    
  }
  defaultFailCallback(response) {
    if (this.vue !== undefined)
    {
      this.vue.loading=false
    }
    if (
      response.data &&
      response.data.status &&
      response.data.status == "error"
    ) {
      if (response.data.error && typeof response.data.error === "object") {
        for (let err in response.data.error) {
          // skip loop if the property is from prototype
          if (!response.data.error.hasOwnProperty(err)) {
            continue;
          }
          for (let msg of response.data.error[err])
            this.vue.$notify({
              title: "Sorry",
              text: msg,
              type: "error"
            });
        }
      } else {
        this.vue.$notify({
          title: "Sorry",
          text: "Unexpected error!",
          type: "error"
        });
      }
    } else if (
      response.data &&
      response.data.status &&
      response.data.status == "fail"
    ) {
      if (response.data.error_message && typeof response.data.error_message == "string") {
        this.vue.$notify({
          title: "Sorry",
          text: response.data.error_message,
          type: "error"
        });
        return;
      }

      if (response.data.error && typeof response.data.error === "object") {
        for (let err in response.data.error) {
          // skip loop if the property is from prototype
          if (!response.data.error.hasOwnProperty(err)) {
            continue;
          }
          for (let msg of response.data.error[err])
            this.vue.$notify({
              title: "Sorry",
              text: msg,
              type: "error"
            });
        }
      } else if (
        response.data.error &&
        typeof response.data.error === "string"
      ) {
        this.vue.$notify({
          title: "Sorry",
          text: response.data.error,
          type: "error"
        });
      } else {
        this.vue.$notify({
          title: "Sorry",
          text: "Unexpected error!",
          type: "error"
        });
      }
    } else {
      console.log('Unexpected data format returned from backend!!',response)
      this.vue.$notify({
        title: "Sorry",
        text: "Unexpected data format returned from backend!!",
        type: "error"
      });
    }
  }
  displayError(error) {
    switch (typeof error) {
      case "string":
        this.vue.$notify({
          title: "Sorry",
          text: error,
          type: "error"
        });
        return;
      case "object":
        for (let key in error) {
          this.displayError(error[key]);
        }
    }
  }
  getImage(img_name, successCallback, failCallback, errorCallback) {
     http
      .request({
        method: ApiUrls.getImage.verb,
        url:ApiUrls.getImage.url+'/'+img_name,
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        responseType:'arraybuffer'
      })
      .then( response => {
        if (
          response.status ==200
        ) {
          successCallback(
            'data:'
            + response.headers['content-type'].toLowerCase()
            +'; base64, '
            + Buffer.from(response.data, 'binary').toString('base64')
          )
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          failCallback(response.data);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
        if (errorCallback !== undefined) {
          errorCallback(error);
        }
      });
  }
  getFile({resource, file_name}, successCallback, failCallback, errorCallback) {
    http
     .request({
       method: 'GET',
       url: resource+'/'+file_name,
       headers: {
         Authorization: `Bearer ${localStorage.token}`,
       },
       responseType:'blob'
     })
     .then( response => {
      // console.log(response);
       if (
         response.status ==200
       ) {
          // let newTab = window.open();
          // newTab.location.href = window.URL.createObjectURL(response.data);
          // return;
          let anchor = document.createElement("a");
          anchor.target="_blank"
          anchor.download=file_name
          document.body.appendChild(anchor);
          let objectUrl= window.URL.createObjectURL(response.data);
          anchor.href =objectUrl
          anchor.click();
          window.URL.revokeObjectURL(objectUrl);
          return;
        }
       if (failCallback === undefined) {
         this.defaultFailCallback(response);
       } else {
         failCallback(response.data);
       }
     })
     .catch(function(error) {
       console.warn("Backend Isssue:-", error);
       if (errorCallback !== undefined) {
         errorCallback(error);
       }
     });
 }
  getProductList(successCallback, failCallback) {
    http
      .request({
        method: ApiUrls.products.verb,
        url: ApiUrls.products.url
      })
      .then(response => {
        // console.log(response);
        if (
          response.data &&
          response.data.status &&
          response.data.status === "success"
        ) {
          
          let {data}=response.data;
          successCallback(
            /*
            Object.keys(data)
            .map(
              key=>(
                {
                  id:key,
                  name:data[key]
                }
              )
            )*/
          data
          );
          return;
        }
        if (failCallback === undefined) {
          this.defaultFailCallback(response);
        } else {
          failCallback(response.data);
        }
      })
      .catch(function(error) {
        console.warn("Backend Isssue:-", error);
      });
  }
}
