<script>
import toggleMenu from "@/helper/toggleMenu";
export default {
  mounted() {
    this.userData = JSON.parse(localStorage.getItem("userdata"));

  },
  methods: {
    logout() {
      localStorage.removeItem("userdata");
      this.$router.go(0);
      // this.$router.push({ path: "/login" });
    },
    navigateTo(path) {
      this.$router.push({ path: path }).catch(err => {
        this.$router.go(0);
      });
    },
    toggleMenu: toggleMenu
  },
  data() {
    return {
      userData: {},
      menuList: "dashboard,profile,training,download,update-password".split(',')
    };
  },
};
</script>

<template>
  <header >
    <b-navbar toggleable="lg" type="light" variant="light" class="header-menu">
      <b-navbar-brand to="dashboard">
        <img class='logo' src="../assets/images/logo.png" alt="CAST Soft Logo"
      /></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-for="(item, index) of menuList"
            :to="'/' + item"
            :disabled="userData.status!='DA' && 'training,download'.includes(item)"
            :key="index">
            {{ item.split('-').join(' ') }}
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item @click="logout">Log Out</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>
