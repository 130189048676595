export default function toggleMenu(){
  setTimeout(() => {
    let menu = this.g_menu;
    // console.log(menu);
    if (menu.classList.contains("open")) {
      menu.classList.remove("open");
    } else {
      menu.classList.add("open");
    }
  });
} 