export default function togglePassword(element) {
    // console.log('@togglePassword,targent element:'+element.tagName, element);
    let passwordElement,parentElement;
    if(element.tagName=="I"){
        passwordElement = element.parentElement.parentElement.parentElement.children[0];
        parentElement = element.parentElement;
    }
    else{//BUTTON
        passwordElement = element.parentElement.parentElement.children[0];
        parentElement = element;
    }
    
    // console.log('element.parentElement', element.parentElement)
    if (passwordElement.type != "password") {
        parentElement.children[1].style.display = "inline-block"; //= "visibility"
        parentElement.children[0].style.display = "none";
        passwordElement.type = "password";
    } else if (passwordElement.type != "text") {
        passwordElement.type = "text";
        parentElement.children[0].style.display = "inline-block"; //= "visibility"
        parentElement.children[1].style.display = "none";
    }
}