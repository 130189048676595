import { required, alpha } from 'vuelidate/lib/validators'

const choose = 'Choose...'
// const validPassword = value =>value.search(/^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{7,}$/) >= 0;

const chooseOption = value => value != choose

export default {
  school: {
    institution: { required },
    address1: { required },
    address2: {  }, 
    country: { chooseOption },
    state: { chooseOption },
    city: { required },
    postal: { required },
    //course: { required }
  }
}
